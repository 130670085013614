/* eslint-disable import/no-extraneous-dependencies */
// import axios from "axios";

// get environment variables
// const baseUrl = "https://newdale-imi.auth.ap-southeast-1.amazoncognito.com";
// const clientId = "3iqu51dtm841mc4bpub9t5fbnb";
const baseUrl = "https://clogard-imi.auth.ap-southeast-1.amazoncognito.com";
const clientId = "hummika6q8qhfmlcrd6808j8t";
const redirectUri = `${window.location.origin}`;

//https://clogard-imi.auth.ap-southeast-1.amazoncognito.com/login?client_id=hummika6q8qhfmlcrd6808j8t&response_type=token&scope=email+openid+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000
//https://newdale-imi.auth.ap-southeast-1.amazoncognito.com/login?client_id=3iqu51dtm841mc4bpub9t5fbnb&response_type=token&scope=email+openid+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000

// new - https://anchor-imi.auth.ap-southeast-1.amazoncognito.com/login?client_id=2k81vgmv13rkv28p322dcroug&response_type=token&scope=email+openid+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000
// helper to get url search param
const getSearchParam = (key) => {
  const url = window.location.href;
  const separator = `${key}=`;
  if (url.includes(separator)) {
    return url.split(separator)[1].split("&")[0].split("#")[0];
  }
  return null;
};

// helper to check token expiry time
const isTokenExpired = (token) => {
  // decode token payload
  const payload = JSON.parse(window.atob(token.split(".")[1]));
  // check with current time
  return payload.exp * 1000 < Date.now();
};

export default {
  /**
   *  Login, signup or logout through Amazon Cognito
   * @param {'login' | 'signup' | 'logout'} type - Authentication type
   */
  authenticate(type) {
    // clear local tokens if on logout
    if (type === "logout") {
      localStorage.removeItem("id_token");
      localStorage.removeItem("access_token");
    }
    // generate cognito url with params
    const endpoint = new URL(`${baseUrl}/${type}`);
    endpoint.searchParams.append("client_id", clientId);
    endpoint.searchParams.append("response_type", "token");
    endpoint.searchParams.append("scope", "email openid profile");
    // endpoint.searchParams.append("redirect_uri", redirectUri);
    // navigate to cognito
    window.location.href = endpoint.toString() + `&redirect_uri=${redirectUri}`;
    
  },
  /** Check tokens on redirect url */
  checkURLTokens() {
    // get tokens from url
    const idToken = getSearchParam("id_token");
    const accessToken = getSearchParam("access_token");
    // check tokens
    if (idToken && accessToken) {
     // console.log(idToken,accessToken);
      localStorage.setItem("id_token", idToken);
      localStorage.setItem("access_token", accessToken);
      // token found in url
      // console.log("check true");
      return true;
    }
    // no token in url
   //  console.log("check false");
    return false;
  },
  /** Get tokens from the local storage */
  getLocalTokens() {
    // get tokens locally
    const idToken = localStorage.getItem("id_token");
    const accessToken = localStorage.getItem("access_token");
    const access_token_2 = localStorage.getItem("access_token_2");

   // console.log(idToken,accessToken);
    // no local tokens
    if (!idToken || !accessToken) {
      return null;
    }
    // tokens are expired
    if (isTokenExpired(idToken) || isTokenExpired(accessToken)) {
      // remove local tokens
      localStorage.removeItem("id_token");
      localStorage.removeItem("access_token");
      return null;
    }
    // return valid token object
    return { idToken, accessToken, access_token_2 };
  },
  /** Fetch user details */
  // getUserData() {
  //   return new Promise((resolve) => {
  //     const tokens = this.getLocalTokens();
  //     if (!tokens) {
  //       resolve(null);
  //     }
  //     axios
  //       .get(`${baseUrl}/oauth2/userInfo`, {
  //         headers: {
  //           Authorization: `Bearer ${tokens.accessToken}`,
  //         },
  //       })
  //       .then((data) => {
  //         resolve(data.data);
  //       });
  //   });
  // },
};
